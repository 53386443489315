import { Mic, MicOff, Phone, PhoneOff } from "lucide-react";
import { useEffect, useRef, useState } from "react";
import {
  ILoggingConfig,
  SecuredCalls,
} from "@expertstack-studios/sc-web-sdk-v2";
import { ConversationEvent, VonageClient } from "@vonage/client-sdk";
import { jwtDecode } from "jwt-decode";
import { useAuth } from "../../auth/AuthContext";

export enum CallingOption {
  InAppCall = "InAppCall",
  PSTNCall = "PSTNCall",
}

interface CallingCardProps {
  id: string;
  logo: string;
  businessName: string;
  isDisabled: boolean;
  onCall?: boolean;
  aNumber: string;
  allowedIntents?: string[];
  callStarted: (id: string) => void;
  callEnded: (id: string) => void;
  callingOption?: CallingOption;
}

const CallingCard: React.FC<CallingCardProps> = ({
  id,
  logo,
  businessName,
  isDisabled,
  onCall = false,
  aNumber,
  allowedIntents,
  callStarted,
  callEnded,
  callingOption = CallingOption.InAppCall,
}) => {
  const { user, logout } = useAuth();
  const [bNumber, setBNumber] = useState<string>("");
  const [scClient, setScClient] = useState<SecuredCalls | null>(null);
  const [intent, setIntent] = useState<string>("not provided");
  const [customIntent, setCustomIntent] = useState(""); // Separate state for custom intent
  const [error, setError] = useState("");
  const [progress, setProgress] = useState("");
  const [showModal, setShowModal] = useState(false); // State for custom modal
  const [showTimeoutModal, setShowTimeoutModal] = useState(false); // State for custom modal
  const [muted, setMuted] = useState(false);
  const [callConnected, setCallConnected] = useState(false);
  const [dialing, setDialing] = useState(false);
  const [token, setToken] = useState<string | null>(null);
  const [callOption, setCallOption] = useState<string | null>(null);
  const [vonageClient, setVonageClient] = useState<VonageClient | null>(null);
  const [sessionId, setSessionId] = useState<string | null>(null);
  const [conversationId, setConversationID] = useState<string>("");
  const [calleeMemberId, setCalleeMemberId] = useState<string>("");
  const [callId, setCallId] = useState<string | null>(null);
  const [brandingId, setBrandingId] = useState<string>("");
  const [sessionExpiredOpen, setSessionExpiredOpen] = useState(false); // State to control session expired modal
  const tokenFetchedRef = useRef<boolean>(false);
  const [callNote, setCallNote] = useState("");
  const maxCallNoteLength = 250;
  const storedFromNumber = useRef<string>("");
  const storedToNumber = useRef<string>("");

  const handleNoteChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setCallNote(event.target.value);
  };

  interface DecodedToken {
    exp: number; // expiration time in seconds since epoch
  }

  const fetchToken = async () => {
    if (!user) {
      console.error("Cannot fetch token as there is no user logged in.");
      return;
    }

    try {
      // console.log(`domain -> ${process.env.REACT_APP_CC_DOMAIN}`);
      const URL = `https://${process.env.REACT_APP_CC_DOMAIN}/cc/me/credentials`;

      const response = await fetch(URL, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${user?.accessToken}`,
        },
      });

      if (response.status === 401) {
        // If the token is expired, log the user out and navigate to login screen
        console.error("Token expired, redirecting to login.");
        setSessionExpiredOpen(true);
        logout();
        return;
      }

      console.debug(
        `CC -> fetchToken response status : ${JSON.stringify(response.status)}`
      );

      const data = await response.json();

      setProgress("");
      setCallNote("");
      return data.scResponse.data.credentials.token;
    } catch (error) {
      console.error("Error fetching token:", error);
      setProgress("Error fetching token");
    }
  };

  const checkTokenExpiry = () => {
    if (user?.accessToken) {
      const decodedToken: DecodedToken = jwtDecode(user.accessToken);
      const currentTime = Date.now() / 1000; // current time in seconds since epoch
      const timeLeft = decodedToken.exp - currentTime;

      if (timeLeft < 60) {
        // If token expires in less than 60 seconds
        console.log(`User Access token about to expire`);
        if (!callId) {
          setSessionExpiredOpen(true);
          logout();
        }
      } else {
        // console.log(
        //   `User Access token is still valid. Timeleft => ${timeLeft}`
        // );
      }
    }
  };

  useEffect(() => {
    let scSecret = `${process.env.REACT_APP_SC_SECRET}`;
    let scConfig = `${process.env.REACT_APP_SC_CONFIG}`;
    const scLoggingConfig: ILoggingConfig = {
      level: "info",
    };
    const scClient = new SecuredCalls(scSecret, scConfig, scLoggingConfig);
    setScClient(scClient);

    scClient.onBrandingSuccess((referenceId) => {
      console.log("CC -> Branding succeeded.");
      setProgress("Branding successful.");

      if (storedFromNumber && storedToNumber) {
        setProgress("Starting call.");
        startCall(storedFromNumber.current, storedToNumber.current);
        setCallConnected(true);
        setBrandingId(referenceId);
      } else {
        console.error("Stored numbers are missing, cannot start the call.");
      }
    });

    scClient.onBrandingFailed((errorMessage) => {
      console.error("CC -> Branding failed with error:", errorMessage);
      setError(errorMessage);
      setProgress("");
      setShowModal(true); // Show modal when branding fails
      callEnded(id);
      setDialing(false);
      setBrandingId("");
    });

    scClient.onBrandingTimedOut(() => {
      console.log("CC -> Branding operation timed out.");
      setCallConnected(false);
      setError("");
      setProgress("");
      setShowModal(false); // Show modal when branding fails
      setShowTimeoutModal(true);
      callEnded(id);
      setDialing(false);
      setBrandingId("");
      setCallId(null);
      setCallNote("");
    });

    console.log(`SCClient has been setup`);

    if (!tokenFetchedRef.current) {
      fetchToken();
      tokenFetchedRef.current = true;
    }

    if (allowedIntents && allowedIntents.length > 0) {
      setIntent(allowedIntents[0]); // Set the default to the first item
    }

    const intervalId = setInterval(checkTokenExpiry, 5000); // check every 5 seconds
    return () => clearInterval(intervalId); // cleanup on component unmount
  }, []); // Runs once on component mount

  const makeCall = async (continueWithoutBranding = false) => {
    console.log(
      `CC -> ${businessName} is starting call from +${aNumber} to ${bNumber}`
    );

    if (!bNumber || bNumber.length < 2) {
      setError("Please provide the number to call");
      return;
    }

    setDialing(true);
    callStarted(id);

    if (aNumber && bNumber) {
      try {
        if (!continueWithoutBranding) {
          setProgress("Branding started");
          setError("");
          const sanitizedANumber = aNumber.startsWith("+")
            ? aNumber.slice(1)
            : aNumber;
          const sanitizedBNumber = bNumber.startsWith("+")
            ? bNumber.slice(1)
            : bNumber;

          storedFromNumber.current = sanitizedANumber;
          storedToNumber.current = sanitizedBNumber;

          await scClient?.setupBrandingAsync(
            storedFromNumber.current,
            storedToNumber.current,
            intent === "Custom" ? customIntent : intent,
            callingOption.toString()
          );
        } else {
          setError("");
          console.log(`starting call from ${aNumber} to ${bNumber}`);
          setProgress("Starting call.");
          startCall(aNumber, bNumber);
          setCallConnected(true);
        }
      } catch (error) {
        setError(`Failed to brand ${error}`);
        setProgress("");
        setShowModal(true); // Show modal if an error occurs
        callEnded(id);
        setDialing(false);
      }
    } else {
      setError("Missing mandatory information to make a call");
      setProgress("");
      callEnded(id);
      setDialing(false);
    }
  };

  const startCall = async (from: string, to: string) => {
    const newToken = await fetchToken();

    if (!newToken) {
      console.log(`CC -> no token available... ${token}`);
      return;
    } else {
    }

    setProgress("Creating session...");
    setVonageClient(null);

    const vonageClient = new VonageClient({
      region: "AP",
    });

    setVonageClient(vonageClient);

    const sessionId = await vonageClient.createSession(newToken);

    if (sessionId) {
      setSessionId(sessionId);
      setProgress("");

      vonageClient.on("conversationEvent", (event: ConversationEvent) => {
        if (event.kind === "member:invited") {
          setConversationID(event.conversationId);
          setCalleeMemberId(event.body.memberId);
        }
      });

      vonageClient.on(
        "legStatusUpdate",
        async (callId: string, legId: string, status: string) => {
          console.info(
            `Leg Status : ${status} for callID ${callId} and legId ${legId}`
          );

          if (status === "RINGING") {
            setCallId(callId);
            setProgress("Call is ringing");
          }
          if (status === "ANSWERED") {
            setCallId(callId);
            setProgress("Call answered");
          }
          if (status === "COMPLETED") {
            console.log(`Start call completed process...`);
            if (brandingId) {
              try {
                console.log(`Branding ID for clean up -> ${brandingId}`);
                // await scClient?.clearBrandingAsync(brandingId);
                console.log(`Branding cleaned up`);
              } catch (error) {
                console.error("Error during branding cleanup:", error);
              }
              setBrandingId("");
            }
            setCallId(null);
            setProgress("Call completed");
          }
        }
      );

      const sanitizedANumber =
        from && from.startsWith("+")
          ? from.slice(1)
          : from.replace(/[^0-9]/g, "");

      const sanitizedBNumber =
        to && to.startsWith("+") ? to.slice(1) : to.replace(/[^0-9]/g, "");

      vonageClient
        ?.serverCall({
          to: sanitizedBNumber,
          from: sanitizedANumber,
          intent,
          isDemo: true,
        })
        .then((_callId: string) => {
          console.log(`Call ID: ${_callId}`);
          setCallId(_callId);
          setProgress("Calling...");
        })
        .catch((error: Error) => {
          console.error(`Error making call: ${error}`);
          setProgress("Error making call");
        });
    } else {
      setSessionId("");
      setProgress("Failed to create session.");
    }
    // setCallNote("");
  };

  const addCallNote = async () => {
    if (!brandingId) {
      console.log("No branding ID available so call not cannot be set");
      return;
    }

    const callNoteAdded = await scClient?.addNoteAsync(brandingId, callNote);

    if (callNoteAdded) {
      setCallNote("");
      console.log(`Call note has been added for branding Id -> ${brandingId}`);
    } else {
      console.log(
        `Call note could not be added for branding Id -> ${brandingId}`
      );
    }
  };

  const hangUp = async () => {
    if (callId) {
      if (!vonageClient) {
        return;
      }

      try {
        if (conversationId && calleeMemberId && callOption !== "PSTN") {
          const hangUpURL = `https://${process.env.REACT_APP_CC_DOMAIN}/cc/conversation/${conversationId}/member/${calleeMemberId}/hangup`;
          const response = await fetch(hangUpURL, {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${user?.accessToken}`,
            },
          });

          console.log(`CC -> Hang up Response -> ${response.status}`);
        }

        vonageClient
          .hangup(callId, "User cancelled")
          .then(() => {
            console.log("CC -> Call successfully hung up");
          })
          .catch((error) => {
            console.error("CC -> Error hanging up the call:", error);
          });

        if (brandingId) {
          console.log(`CC -> Clearing branding`);
          // await scClient?.clearBrandingAsync(brandingId);
          vonageClient.deleteSession();
          setBrandingId("");
        }

        setProgress("Call ended");
        setCallId(null);
        setCallNote("");

        // fetchToken(); // Regenerate token after the call ends
      } catch (error) {
        if (brandingId) {
          console.log(`Clearing branding for ${brandingId}`);
          await scClient?.clearBrandingAsync(brandingId);
          vonageClient.deleteSession();
          setBrandingId("");
        }
        console.error("Error hanging up call:", error);
        setProgress("Error hanging up call");
        setCallId(null);
        setBrandingId("");
        setCallNote("");
        // fetchToken(); // Regenerate token after the call ends
      }
    }
  };

  const mute = async () => {
    if (vonageClient && callId) {
      console.log(`muting`);
      await vonageClient.mute(callId);
      setMuted(true);
    }
  };

  const unMute = async () => {
    if (vonageClient && callId) {
      console.log(`unmuting`);
      await vonageClient.unmute(callId);
      setMuted(false);
    }
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const handleCloseTimeoutModal = () => {
    setShowTimeoutModal(false);
  };

  const handleContinueWithoutBranding = () => {
    setShowModal(false);
    setShowTimeoutModal(false);
    makeCall(true); // Continue without branding
  };

  return (
    <div className="flex flex-col items-center justify-evenly gap-2">
      <div
        className={`${
          callConnected
            ? "bg-gradient-to-tr from-slate-950 to-sky-900"
            : "bg-gray-700"
        } font-bold w-[400px] p-[24px] rounded-3xl text-center flex flex-col items-center justify-evenly gap-4 ${
          isDisabled ? "opacity-50 pointer-events-none" : ""
        } ${callConnected ? "border-2" : ""} drop-shadow-lg `}
      >
        {businessName && (
          <div className="flex flex-col items-center justify-evenly">
            {id === "general" && (
              <span className="font-Poppins bg-clip-text bg-gradient-to-r from-purple-500 to-sky-500 text-3xl  text-transparent">
                {businessName}
              </span>
            )}
            {id === "fd" && (
              <span className="font-Poppins bg-clip-text bg-gradient-to-r from-purple-500 to-sky-500 text-4xl uppercase text-transparent">
                {businessName}
              </span>
            )}
            {id === "ab" && (
              <span className="bg-clip-text bg-gradient-to-r from-green-500 to-white text-4xl uppercase text-transparent">
                {businessName}
              </span>
            )}
            {id === "vd" && (
              <span className="bg-clip-text bg-gradient-to-r from-yellow-500 to-yellow-800 text-4xl uppercase text-transparent">
                {businessName}
              </span>
            )}
          </div>
        )}
        <span
          className={`font-Poppins font-medium ${
            id === "general" ? "text-[24px]" : "text-lg"
          } uppercase text-white`}
        >
          +{aNumber}
        </span>
        {logo && (
          <img
            className="h-48 w-48 rounded-full border-white border-8 drop-shadow-lg"
            src={logo}
            alt={`${businessName} Logo`}
          />
        )}
        <input
          className="font-Poppins font-medium h-12 w-full text-white text-xl p-4 bg-slate-800 border-b-2 focus:outline-none"
          type="text"
          inputMode="numeric"
          placeholder="enter customer's number"
          pattern="[0-9+]*"
          disabled={isDisabled}
          value={bNumber}
          onChange={(e) => {
            let inputValue = e.target.value;

            if (!inputValue.startsWith("+")) {
              inputValue = `+${inputValue.replace(/[^0-9]/g, "")}`;
            } else {
              inputValue = `+${inputValue.slice(1).replace(/[^0-9]/g, "")}`;
            }

            setBNumber(inputValue);
          }}
        />
        <div className="w-full">
          {allowedIntents && (
            <div className="flex flex-col justify-evenly">
              <select
                className="font-Poppins font-normal h-12 w-full bg-gray-800 text-white p-2"
                value={intent}
                onChange={(e) => {
                  const selectedIntent = e.target.value;
                  setIntent(selectedIntent);

                  if (selectedIntent !== "Custom") {
                    setCustomIntent("");
                  }
                }}
                disabled={isDisabled}
              >
                {allowedIntents.map((intent: string) => (
                  <option key={intent} value={intent}>
                    {intent}
                  </option>
                ))}
              </select>

              {intent === "Custom" && (
                <input
                  className={`font-Poppins font-normal h-12 w-full p-4 ${
                    intent !== "Custom" || isDisabled
                      ? "bg-slate-500"
                      : "bg-gray-800"
                  }  text-white mt-4 border-b-2`}
                  type="text"
                  maxLength={32}
                  disabled={intent !== "Custom" || isDisabled}
                  value={customIntent}
                  onChange={(e) => setCustomIntent(e.target.value)}
                  placeholder="Enter custom intent"
                />
              )}
            </div>
          )}
        </div>
        <div
          className={`bg-gray-800 rounded-full p-2 text-white ${
            callConnected ? "" : "hover:text-green-500"
          } `}
        >
          <button
            onClick={() => makeCall()}
            className={`p-8 rounded-full border-white border-8 focus:outline-none transition-all transform ${
              callConnected || dialing
                ? "opacity-50 cursor-not-allowed" // Disabled styles
                : "hover:border-green-500 active:scale-95 hover:shadow-lg" // Hover and active states
            }`}
            disabled={callConnected || dialing}
          >
            <Phone className="h-12 w-12" />
          </button>
        </div>
        <label className="text-white font-Poppins font-normal">
          Branded Call
        </label>
        <div
          className={`bg-gray-800 rounded-full p-2 text-white ${
            callConnected ? "" : "hover:text-green-500"
          } `}
        >
          <button
            onClick={() => handleContinueWithoutBranding()}
            className={`p-8 rounded-full border-red-600 text-red-600 border-8 focus:outline-none transition-all transform ${
              callConnected || dialing
                ? "opacity-50 cursor-not-allowed" // Disabled styles
                : "hover:border-green-500 active:scale-95 hover:shadow-lg" // Hover and active states
            }`}
            disabled={callConnected || dialing}
          >
            <Phone className="h-12 w-12" />
          </button>
        </div>
        <label className="text-white font-Poppins font-normal">
          Unbranded Call
        </label>
        {/* Control Panel */}
        {callConnected && (
          <div className="flex flex-col items-center justify-evenly">
            <div className="  flex justify-center gap-4 rounded-full p-2">
              {muted ? (
                <button className=" w-8 h-8 text-red-600 rounded-full flex items-center justify-center hover:bg-gray-800 ">
                  <MicOff
                    className="bg-gray-400 text-white w-8 h-8 rounded-full p-2"
                    onClick={async () => await unMute()}
                  />
                </button>
              ) : (
                <button className="w-8 h-8 text-red-600 rounded-full flex items-center justify-center hover:bg-gray-800 ">
                  <Mic
                    className="bg-gray-400 text-white w-8 h-8 rounded-full p-2"
                    onClick={async () => await mute()}
                  />
                </button>
              )}
              {/* {loudSpeaker ? (
              <button className="bg-gray-400 w-8 h-8 text-red-600 rounded-full flex items-center justify-center hover:bg-gray-500 hover:text-red-700 transition-all">
                <Volume2 className="bg-gray-400 text-white w-8 h-8 rounded-full p-2" />
              </button>
            ) : (
              <button className="bg-gray-400 w-8 h-8 text-red-600 rounded-full flex items-center justify-center hover:bg-gray-500 hover:text-red-700 transition-all">
                <VolumeOff className="bg-gray-400 text-white w-8 h-8 rounded-full p-2" />
              </button>
            )} */}
              <button
                className="bg-red-600 w-8 h-8 text-white rounded-full flex items-center justify-center hover:bg-red-700 transition-all"
                onClick={async () => {
                  await hangUp();
                  setCallConnected(false);
                  setProgress("");
                  callEnded(id);
                  setDialing(false);
                }}
              >
                <PhoneOff className="w-4 h-4" />
              </button>
            </div>
          </div>
        )}
      </div>
      {/* Fixed height container to avoid pushing content */}
      {/* Progress Updates */}
      <div className="h-8 flex items-center">
        {progress && (
          <span className="bg-green-500 p-2 w-auto rounded-2xl text-white font-normal">
            {progress}
          </span>
        )}
        {error && (
          <span className="bg-red-500 p-2 w-auto rounded-2xl text-white font-normal">
            {error}
          </span>
        )}
      </div>

      {callConnected && (
        <>
          <br></br>
          <div className="flex flex-col items-center justify-evenly gap-2">
            <div
              className={`${
                callConnected
                  ? "bg-gradient-to-tr from-slate-950 to-sky-900"
                  : "bg-gray-700"
              } font-bold w-[400px] p-[24px] rounded-3xl text-center flex flex-col items-center justify-evenly gap-4 ${
                isDisabled ? "opacity-50 pointer-events-none" : ""
              } ${callConnected ? "border-2" : ""} drop-shadow-lg `}
            >
              <label
                htmlFor="callNote"
                style={{
                  display: "block",
                  color: "white",
                  marginBottom: "8px",
                }}
              >
                Enter note
              </label>
              <textarea
                id="callNote"
                value={callNote}
                onChange={handleNoteChange}
                rows={5}
                disabled={!brandingId}
                style={{
                  width: "100%",
                  padding: "8px",
                  boxSizing: "border-box",
                  resize: "none",
                  marginBottom: "8px",
                }}
              />
              <div
                style={{
                  textAlign: "right",
                  fontSize: "12px",
                  color: "#888",
                  marginBottom: "8px",
                }}
              >
                {maxCallNoteLength - callNote.length} / {maxCallNoteLength}
              </div>
              <button
                onClick={async () => {
                  await addCallNote();
                }}
                disabled={!brandingId || callNote.length === 0}
                style={{
                  width: "100%",
                  padding: "10px",
                  backgroundColor: "#f44336",
                  color: "white",
                  border: "none",
                  cursor:
                    !brandingId || callNote.length === 0
                      ? "not-allowed"
                      : "pointer",
                }}
              >
                Add Call Note
              </button>
            </div>
          </div>
        </>
      )}
      {/* Custom Modal */}
      {showModal && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
          <div className="bg-white p-8 rounded-lg shadow-lg max-w-md text-center z-50">
            <h2 className="text-xl font-semibold mb-4">Branding Failed</h2>
            <p className="mb-4">
              Would you like to continue the call without branding?
            </p>
            <div className="flex justify-around">
              <button
                className="bg-red-500 text-white px-4 py-2 rounded"
                onClick={handleCloseModal}
              >
                No
              </button>
              <button
                className="bg-green-500 text-white px-4 py-2 rounded"
                onClick={handleContinueWithoutBranding}
              >
                Yes, Continue
              </button>
            </div>
          </div>
        </div>
      )}

      {showTimeoutModal && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
          <div className="bg-white p-8 rounded-lg shadow-lg max-w-md text-center z-50">
            <h2 className="text-xl font-semibold mb-4">
              Branding has timedout
            </h2>
            <p className="mb-4">
              Would you like to continue the call without branding?
            </p>
            <div className="flex justify-around">
              <button
                className="bg-red-500 text-white px-4 py-2 rounded"
                onClick={handleCloseTimeoutModal}
              >
                No
              </button>
              <button
                className="bg-green-500 text-white px-4 py-2 rounded"
                onClick={handleContinueWithoutBranding}
              >
                Yes, Continue
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default CallingCard;
